/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import strapiCloud from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=4f9ec1d4";
import usersPermissions from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=4f9ec1d4";
import { renderAdmin } from "/admin/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=4f9ec1d4";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    "strapi-cloud": strapiCloud,
    "users-permissions": usersPermissions,
  },
});
